import React, { useEffect, useRef, useState } from "react"
import { isEqual } from "lodash"
import axios from "axios"
import GoogleMap from "../components/GoogleMap"
import DropDown from "../components/DropDown"
import Layout from "../components/Layout"
import { Phone, MapMarker } from "../components/Icons"
import * as util from "../common/util"
import activeWelcomeChurchMarker from "../assets/images/Active Welcome Church Location Marker.svg"
import registeredWelcomeChurchMarker from "../assets/images/Registered Welcome Church Location Marker.svg"

const resourceRefs = new Map()
let prevRef

const createResourceRef = key => {
  if (resourceRefs.has(key)) return

  const ref = useRef(null)
  resourceRefs.set(key, ref)

  return ref
}

const unhighlightPrevResource = () => {
  if (prevRef) {
    prevRef.style.border = "none"
    prevRef.style.borderBottom = "1px solid rgb(224, 221, 221)"
  }
}

const highlightResource = (key, scrollIntoView = true) => {
  unhighlightPrevResource()

  const ref = resourceRefs.get(key).current

  if (ref) {
    ref.style.border = "5px solid #00bfd8"
    ref.style.borderRadius = "5px"

    if (scrollIntoView)
      ref.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      })

    prevRef = ref
  }
}

const SearchResults = ({
  filteredResources,
  isLoading,
  setCenterMarkerKey,
}) => {
  filteredResources.forEach(resource => {
    createResourceRef(resource.id)
  })

  return (
    <section className="search__results">
      {filteredResources.length === 0
        ? isLoading
          ? "Loading resources..."
          : "No resources found!"
        : filteredResources.map((item, i) => (
            <div
              key={i}
              className="search__item"
              ref={resourceRefs.get(item.id)}
              onClick={() => {
                highlightResource(item.id, false)
                setCenterMarkerKey(item.id)
              }}
            >
              <div className="search__item-info">
                <h2 className="search--name">{item.name}</h2>
                <p className="search--contact-info">
                  {item.address}, {item.city}, {item.province}
                </p>
                {item.website && (
                  <a
                    className="search--website"
                    href={
                      item.website.startsWith("http")
                        ? item.website
                        : "https://" + item.website
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.website}
                  </a>
                )}
                <p className="search--contact-info">
                  <Phone className="phone__icon" />
                  {item.phone}
                </p>
              </div>
              <div key={i} className="search__item-photo">
                <img src={item.url_photo} alt="resource"></img>
              </div>
            </div>
          ))}
    </section>
  )
}

const RESOURCE_TYPE_TO_MARKER_COLOR_MAP = {
  "Refugee House": "blue",
  "Settlement Agency": "green",
  "Women Shelter": "red",
  "Welcome Church": "orange",
}
const RESOURCE_TYPE_QUERY_PARAM_TO_RESOURCE_TYPE_MAP = {
  refugeehouse: "Refugee House",
  settlementagency: "Settlement Agency",
  womenshelter: "Women Shelter",
  welcomechurch: "Welcome Church",
}
const PROVINCE_ABBREVIATION_TO_PROVINCE_MAP = {
  NL: "Newfoundland and Labrador",
  PE: "Prince Edward Island",
  NS: "Nova Scotia",
  NB: "New Brunswick",
  QC: "Quebec",
  ON: "Ontario",
  MB: "Manitoba",
  SK: "Saskatchewan",
  AB: "Alberta",
  BC: "British Columbia",
  YT: "Yukon",
  NT: "Northwest Territories",
  NU: "Nunavut",
}
const LOCATION_OPTIONS_MAP = {
  anywhere: "Anywhere",
  user_location: "Near my current location",
  map_area: "Within the current map area",
}
const USER_LOCATION_DISTANCE_RADIUS_IN_KM = 25

const ResourceMap = () => {
  const initialResourceType =
    RESOURCE_TYPE_QUERY_PARAM_TO_RESOURCE_TYPE_MAP[
      util.getQueryParam("resourcetype")
    ] ?? ""

  const [allResources, setAllResources] = useState([])
  const [searchOptions, setSearchOptions] = useState({})
  const [searchTerm, setSearchTerm] = useState("")
  const [searchProvince, setSearchProvince] = useState("")
  const [searchResourceType, setSearchResourceType] = useState(
    initialResourceType
  )
  const [searchLocation, setSearchLocation] = useState(
    LOCATION_OPTIONS_MAP["anywhere"]
  )
  const [filteredResources, setFilteredResources] = useState([])
  const [markers, setMarkers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [centerMarkerKey, setCenterMarkerKey] = useState("")
  const [mapBounds, setMapBounds] = useState(null)
  const [userLocation, setUserLocation] = useState(null)

  const resetSearchFilters = () => {
    setSearchLocation(LOCATION_OPTIONS_MAP["anywhere"])
    setUserLocation(null)

    setSearchTerm("")
    setSearchProvince("")
    setSearchResourceType("")

    setCenterMarkerKey("")
    unhighlightPrevResource()
  }

  useEffect(() => {
    axios.get(`${process.env.GATSBY_API_URL}/orgs`).then(response => {
      setAllResources(response.data.records)
      setIsLoading(false)
    })
    axios
      .get(`${process.env.GATSBY_API_URL}/orgs/searchoptions`)
      .then(response => {
        setSearchOptions(response.data.record)
      })
  }, [])

  useEffect(() => {
    // Why? Map area would probably not contain any markers if province is set to one that doesn't contain the user's location
    if (searchLocation == LOCATION_OPTIONS_MAP["user_location"]) {
      setSearchProvince("")
    }
  }, [searchLocation])

  useEffect(() => {
    let filtered = allResources

    if (searchTerm)
      filtered = filtered.filter(resource =>
        `${resource.name} ${resource.address} ${resource.city} ${
          resource.province
        } ${PROVINCE_ABBREVIATION_TO_PROVINCE_MAP[resource.province] ?? ""} ${
          resource.website
        } ${resource.phone} ${resource.phone.replace(/-/g, "")}`
          .toLocaleUpperCase()
          .includes(searchTerm.toLocaleUpperCase())
      )
    if (searchProvince)
      filtered = filtered.filter(
        resource => resource.province === searchProvince
      )
    if (searchResourceType)
      filtered = filtered.filter(resource =>
        resource.resource_name.includes(searchResourceType)
      )
    if (searchLocation)
      filtered = filtered.filter(resource =>
        isWithinLocation(
          searchLocation,
          userLocation,
          mapBounds,
          resource.latitude,
          resource.longitude
        )
      )

    if (!isEqual(filtered, filteredResources)) {
      setFilteredResources(filtered)
      setCenterMarkerKey("")
      unhighlightPrevResource()
    }
  }, [
    allResources,
    searchTerm,
    searchProvince,
    searchResourceType,
    searchLocation,
    mapBounds,
  ])

  useEffect(() => {
    setMarkers(
      filteredResources.map(resource => ({
        key: resource.id,
        location: {
          lat: parseFloat(resource.latitude),
          lng: parseFloat(resource.longitude),
        },
        color:
          RESOURCE_TYPE_TO_MARKER_COLOR_MAP[resource.resource_name] ?? "black",
        welcomeChurchRegistrationStatus:
          resource.resource_name == "Welcome Church"
            ? resource.reg_status
            : null,
      }))
    )
  }, [filteredResources])

  return (
    <Layout>
      <section className="header">
        <h1 className="header__title">Resource Map</h1>
      </section>

      <section className="search">
        <div className="search__filter">
          <div className="form__item">
            <label htmlFor="city" className="item__label">
              Search
            </label>
            <input
              type="text"
              className="search__input"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              placeholder="Start typing..."
            />
          </div>
        </div>
        <div className="search__filter">
          <DropDown
            id="province"
            name="province"
            label="Province"
            value={searchProvince}
            options={
              searchOptions.provinces ? ["", ...searchOptions.provinces] : [""]
            }
            onChange={e => setSearchProvince(e.target.value)}
          />
        </div>
        <div className="search__filter">
          <DropDown
            id="resource_type"
            name="resource_type"
            label="Resource Type"
            value={searchResourceType}
            options={
              searchOptions.resource_names
                ? ["", ...searchOptions.resource_names]
                : [""]
            }
            onChange={e => setSearchResourceType(e.target.value)}
          />
        </div>
        <div className="search__filter">
          <DropDown
            id="location"
            name="location"
            label="Location"
            value={searchLocation}
            options={Object.values(LOCATION_OPTIONS_MAP)}
            onChange={e =>
              askLocationPermissionIfNeededAndSetSearchAndUserLocation(
                e.target.value,
                setSearchLocation,
                setUserLocation
              )
            }
          />
        </div>
        <div className="search__filter">
          <button className="btn search__reset" onClick={resetSearchFilters}>
            Reset
          </button>
        </div>
      </section>

      <section className="search__resource-finder">
        <SearchResults
          filteredResources={filteredResources}
          isLoading={isLoading}
          setCenterMarkerKey={setCenterMarkerKey}
        />
        <GoogleMap
          markers={markers}
          onClickMarker={key => {
            highlightResource(key)
            setCenterMarkerKey(key)
          }}
          centerMarkerKey={centerMarkerKey}
          userLocation={userLocation}
          onBoundsChanged={bounds => {
            setMapBounds(bounds)
          }}
        />
      </section>

      <section className="search">
        <div className="search__legend">
          <div className="search__legend-item">
            <MapMarker className="search__legend-item-image" fill="blue" />
            <strong className="search__legend-text">Refugee House</strong>
          </div>
          <div className="search__legend-item">
            <MapMarker className="search__legend-item-image" fill="green" />
            <strong className="search__legend-text">Settlement Agency</strong>
          </div>
          <div className="search__legend-item">
            <MapMarker className="search__legend-item-image" fill="red" />
            <strong className="search__legend-text">Women Shelter</strong>
          </div>
          <div className="search__legend-item">
            <img
              src={registeredWelcomeChurchMarker}
              alt="Registered Welcome Church icon"
            />
            <strong className="search__legend-text">
              Registered
              <br />
              Welcome Church
            </strong>
          </div>
          <div className="search__legend-item">
            <img
              src={activeWelcomeChurchMarker}
              alt="Active Welcome Church icon"
            />
            <strong className="search__legend-text">
              Active
              <br />
              Welcome Church
            </strong>
          </div>
        </div>
      </section>
    </Layout>
  )
}

const isWithinLocation = (
  searchLocation,
  userLocation,
  mapBounds,
  latitude,
  longitude
) => {
  switch (searchLocation) {
    case LOCATION_OPTIONS_MAP["anywhere"]:
      return true
    case LOCATION_OPTIONS_MAP["user_location"]:
      return isWithinUserLocation(userLocation, latitude, longitude)
    case LOCATION_OPTIONS_MAP["map_area"]:
      return isWithinMapArea(mapBounds, latitude, longitude)
    default:
      return true
  }
}

const isWithinUserLocation = (userLocation, latitude, longitude) => {
  const distanceInKm = getDistanceBetweenTwoPointsInKm(
    userLocation.lat,
    userLocation.lng,
    latitude,
    longitude
  )

  return distanceInKm <= USER_LOCATION_DISTANCE_RADIUS_IN_KM
}

const isWithinMapArea = (mapBounds, latitude, longitude) => {
  if (
    mapBounds == null ||
    mapBounds.north == null ||
    mapBounds.south == null ||
    mapBounds.west == null ||
    mapBounds.east == null
  ) {
    return false
  }

  const isWithinVerticalBounds =
    latitude < mapBounds.north && latitude > mapBounds.south
  const isWithinHorizontalBounds =
    longitude > mapBounds.west && longitude < mapBounds.east

  return isWithinVerticalBounds && isWithinHorizontalBounds
}

const askLocationPermissionIfNeededAndSetSearchAndUserLocation = (
  newSearchLocation,
  setSearchLocation,
  setUserLocation
) => {
  if (newSearchLocation == LOCATION_OPTIONS_MAP["user_location"]) {
    navigator.geolocation.getCurrentPosition(
      position =>
        locationPermissionSuccessCallback(
          position,
          setSearchLocation,
          setUserLocation
        ),
      locationPermissionErrorCallback
    )
  } else {
    setSearchLocation(newSearchLocation)
  }
}

const locationPermissionSuccessCallback = (
  position,
  setSearchLocation,
  setUserLocation
) => {
  if (position.coords.latitude && position.coords.longitude) {
    setUserLocation({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    })
    setSearchLocation(LOCATION_OPTIONS_MAP["user_location"])
  }
}

const locationPermissionErrorCallback = error => {
  if (
    error.message == "User denied Geolocation" ||
    error.message == "User has not allowed access to system location."
  ) {
    alert("Please allow location permissions and try again.")
  } else {
    alert("Unexpected location permissions error, please try again later.")
  }
}

// Haversine formula
const getDistanceBetweenTwoPointsInKm = (lat1, lng1, lat2, lng2) => {
  const R = 6371 // radius of the earth in km
  const dLat = degreesToRadians(lat2 - lat1)
  const dLng = degreesToRadians(lng2 - lng1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degreesToRadians(lat1)) *
      Math.cos(degreesToRadians(lat2)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return R * c
}

const degreesToRadians = degrees => {
  return degrees * (Math.PI / 180)
}

export default ResourceMap
