import React from "react"

const DropDown = props => {
  const { id, name, label, value, options, required, onChange } = props

  const handleTextChange = e => {
    onChange(e)
  }

  return (
    <div className="form__item">
      <label htmlFor={id} className="item__label">
        {label}
      </label>
      {!!required && <span className="input--required">*</span>}
      <select
        className="item__input"
        id={id}
        name={name}
        required={required}
        onChange={handleTextChange}
        onBlur={handleTextChange}
        value={value}
      >
        {options && options.map(item => <option key={item}>{item}</option>)}
      </select>
    </div>
  )
}

export default DropDown
