import React, { useEffect } from "react"
import { Loader } from "@googlemaps/js-api-loader"
import MarkerClusterer from "@googlemaps/markerclustererplus"
import activeWelcomeChurchMarker from "../assets/images/Active Welcome Church Location Marker.svg"
import registeredWelcomeChurchMarker from "../assets/images/Registered Welcome Church Location Marker.svg"

const DEFAULT_ZOOM = 5
const DEFAULT_CENTER_POSITION = { lat: 0, lng: 0 }
const getWelcomeChurchMarker = status => {
  switch (status) {
    case "Active":
      return { url: activeWelcomeChurchMarker }
    case "Verified":
      return { url: registeredWelcomeChurchMarker }
    default:
      return null
  }
}

const loader = new Loader({
  apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
  version: "weekly",
  libraries: ["places"],
})
const googleMarkersMap = new Map()

let map
let markerClusterer

const GoogleMap = ({
  markers,
  onClickMarker,
  centerMarkerKey,
  userLocation,
  onBoundsChanged,
}) => {
  useEffect(() => {
    initializeMap(onBoundsChanged)
  }, [])

  useEffect(() => {
    updateGoogleMarkers(markers, onClickMarker, onBoundsChanged)
  }, [markers])

  useEffect(() => {
    if (map != null) {
      const centerMarker = googleMarkersMap.get(centerMarkerKey)

      if (centerMarker) {
        map.setCenter(centerMarker.position)
        map.setZoom(15)
      }
    }
  }, [centerMarkerKey])

  useEffect(() => {
    if (map != null && userLocation != null) {
      map.setCenter(userLocation)
      map.setZoom(10)
    }
  }, [userLocation])

  return <div id="map" className="map" />
}

const initializeMap = onBoundsChanged => {
  loader
    .load()
    .then(google => {
      map = new google.maps.Map(document.getElementById("map"), {
        zoom: DEFAULT_ZOOM,
        center: DEFAULT_CENTER_POSITION,
      })

      map.addListener("dragend", () => {
        onBoundsChanged(map.getBounds()?.toJSON())
      })

      map.addListener("zoom_changed", () => {
        onBoundsChanged(map.getBounds()?.toJSON())
      })

      map.setOptions({
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "poi.medical",
            stylers: [{ visibility: "on" }],
          },
        ],
      })

      markerClusterer = new MarkerClusterer(map, [], {
        imagePath:
          "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
      })
    })
    .catch(e => {
      console.log(e)
    })
}

const updateGoogleMarkers = (markers, onClickMarker, onBoundsChanged) => {
  loader
    .load()
    .then(google => {
      clearMarkers()

      let googleMarkers = []
      markers.forEach(marker => {
        if (!googleMarkersMap.has(marker.key)) {
          const newGoogleMarker = new google.maps.Marker({
            position: marker.location,
            icon: getWelcomeChurchMarker(
              marker.welcomeChurchRegistrationStatus
            ) ?? {
              path:
                "M81.2,0H18.7c-3.5,0-6.2,2.8-6.2,6.2v62.6c0,3.5,2.8,6.2,6.2,6.2h20l9.5,22.8c1,2.9,2.5,2.9,3.5,0l9.4-22.7h20c3.5,0,6.3-2.8,6.3-6.2V6.2C87.5,2.8,84.7,0,81.2,0z",
              fillColor: marker.color,
              fillOpacity: 1,
              strokeWeight: 0,
              rotation: 0,
              scale: 0.33,
              anchor: new google.maps.Point(50, 100),
            },
          })

          newGoogleMarker.addListener("click", () => {
            onClickMarker(marker.key)
          })

          googleMarkersMap.set(marker.key, newGoogleMarker)
        }

        const googleMarker = googleMarkersMap.get(marker.key)

        // Why? Map is cleared for all markers in clearMarkers()
        // We want to re-set the map for the markers we want to display
        googleMarker.setMap(map)

        googleMarkers.push(googleMarker)
      })

      markerClusterer.addMarkers(googleMarkers)

      // Why? Attempt to show marker(s) in the map area initially
      if (
        map.getCenter().lat() == DEFAULT_CENTER_POSITION.lat &&
        map.getCenter().lng() == DEFAULT_CENTER_POSITION.lng
      ) {
        map.setCenter(
          googleMarkers[0]?.position ||
            googleMarkersMap.values().next().value?.position ||
            DEFAULT_CENTER_POSITION
        )

        // Why? The bounds are null when the dragend and zoom_changed listeners initially trigger
        onBoundsChanged(map.getBounds()?.toJSON())
      }
    })
    .catch(e => {
      console.log(e)
    })
}

const clearMarkers = () => {
  markerClusterer.clearMarkers()

  // Why? Clear markers from memory
  for (const googleMarker of googleMarkersMap.values()) {
    googleMarker.setMap(null)
  }
}

export default GoogleMap
